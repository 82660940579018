import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/apps/UserListing.vue"),
      
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/apps/UserProfile.vue"),
      
      },
      {
        path: "/clients",
        name: "clients",
        component: () => import("@/views/apps/ClientListing.vue"),
      
      },
      {
        path: "/clientDetail",
        name: "clientDetail",
        component: () => import("@/views/apps/ClientDetail.vue"),
      
      },
      {
        path: "/learningzone",
        name: "learningzone",
        component: () => import("@/views/apps/LearningZone.vue"),
        
      },

      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/apps/Settings.vue"),
        
      },

        ],
      },
  {
  path: "/",
  component: () => import("@/layout/ClientLayout.vue"),
  children: [
    {
      path: "/welcome",
      name: "welcome",
      component: () => import("@/views/Welcome.vue"),
      children: [
        {
        path: "/dash",
        name: "dash",
        component: () => import("@/views/Dashboard.vue")
      },
      ],
    },
    {
      path: "/learnings",
      name: "learnings",
      component: () => import("@/views/Learnings.vue"),
    },
    {
      path: "/funnel",
      name: "funnel",
      component: () => import("@/views/apps/Funnel.vue")
    },
    {
      path: "/webpage",
      name: "webpage",
      component: () => import("@/views/apps/Webpage.vue")
    },
    {
      path: "/funnel-learning-zone",
      name: "funnel-learning-zone",
      component: () => import("@/views/apps/FunnelsLearningZone.vue")
    },
    {
      path: "/webpage-learning-zone",
      name: "webpage-learning-zone",
      component: () => import("@/views/apps/WebpageLearningZone.vue")
    },

    {
      path: "/tracking",
      name: "tracking",
      component: () => import("@/views/apps/Tracking.vue")
    },
  ]
    },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
const restrictedRoutes = ['user','groups']
// const userType = store.getters.currentUser.user_type
router.beforeEach((to,from ,next) => {
  let clientRedirect = '/welcome';
  let clientRedirectName = 'welcome';
  // if(store.getters.currentUser.is_form_enabled == "no"){
  //   clientRedirect = '/learnings';
  //   clientRedirectName = 'learnings';
  // }
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  if (to.name === 'sign-in' && JwtService.getToken()) {
    if(store.getters.currentUser.role_id==2){
      next({ path: clientRedirect });
    }else {
      next({ path: '/dashboard' });
    }
  }
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
  if(to.name ==='dashboard' && JwtService.getToken() && store.getters.currentUser.role_id==2){
    router.push({
      name: clientRedirectName
    })
  }
  if(to.name === clientRedirectName && JwtService.getToken() && store.getters.currentUser.role_id==1){
      router.push({
        name: 'dashboard'
      })
  }
  if(to.name == 'users' || to.name == 'groups'){
    if(store.getters.currentUser.role_id==1){
      next();
    }else {
      next({ path: '/dashboard' });
    }
  }else{
    next()
  }
  
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
